#cancha.form-343 .jugador.j00 {
    top: 75%;
    left: 43.6%
}

#cancha.form-343 .jugador.j01 {
    top: 60%;
    left: 26%
}

#cancha.form-343 .jugador.j02 {
    top: 60%;
    left: 43.6%
}

#cancha.form-343 .jugador.j03 {
    top: 60%;
    left: 61%
}

#cancha.form-343 .jugador.j04 {
    top: 45%;
    left: 20%
}

#cancha.form-343 .jugador.j05 {
    top: 45%;
    left: 35%
}

#cancha.form-343 .jugador.j06 {
    top: 45%;
    left: 51%
}

#cancha.form-343 .jugador.j07 {
    top: 45%;
    left: 67%
}

#cancha.form-343 .jugador.j08 {
    top: 30%;
    left: 26%
}

#cancha.form-343 .jugador.j09 {
    top: 30%;
    left: 43.6%
}

#cancha.form-343 .jugador.j10 {
    top: 30%;
    left: 61%
}
@media screen and (max-width:992px) {
    #cancha.form-343 .jugador.j00 {
        top: 73%;
        left: 41.6%
    }
    #cancha.form-343 .jugador.j01 {
        top: 56.5%;
        left: 22%
    }
    #cancha.form-343 .jugador.j02 {
        top: 56.5%;
        left: 41.6%
    }
    #cancha.form-343 .jugador.j03 {
        top: 56.5%;
        left: 61%
    }
    #cancha.form-343 .jugador.j04 {
        top: 40%;
        left: 15%
    }
    #cancha.form-343 .jugador.j05 {
        top: 40%;
        left: 33%
    }
    #cancha.form-343 .jugador.j06 {
        top: 40%;
        left: 51%
    }
    #cancha.form-343 .jugador.j07 {
        top: 40%;
        left: 69%
    }
    #cancha.form-343 .jugador.j08 {
        top: 23.5%;
        left: 22%
    }
    #cancha.form-343 .jugador.j09 {
        top: 23.5%;
        left: 41.6%
    }
    #cancha.form-343 .jugador.j10 {
        top: 23.5%;
        left: 61%
    }
}
@media screen and (max-width:767px) {
    #cancha.form-343 .jugador.j00 {
        top: -2.000001%;
        left: 37.599999999%
    }
    #cancha.form-343 .jugador.j01 {
        top: 12.0000001%;
        left: 7.00001%
    }
    #cancha.form-343 .jugador.j02 {
        top: 12.0000001%;
        left: 37.599999999%
    }
    #cancha.form-343 .jugador.j03 {
        top: 12.0000001%;
        left: 68.00001%
    }
    #cancha.form-343 .jugador.j04 {
        top: 40.0000001%;
        left: 2.00001%
    }
    #cancha.form-343 .jugador.j05 {
        top: 34.0000001%;
        left: 22.00001%
    }
    #cancha.form-343 .jugador.j06 {
        top: 34.0000001%;
        left: 51.00001%
    }
    #cancha.form-343 .jugador.j07 {
        top: 40.0000001%;
        left: 72.00001%
    }
    #cancha.form-343 .jugador.j08 {
        top: 58.0000001%;
        left: 7.00001%
    }
    #cancha.form-343 .jugador.j09 {
        top: 58.0000001%;
        left: 37.599999999%
    }
    #cancha.form-343 .jugador.j10 {
        top: 58.0000001%;
        left: 68.00001%
    }
}
@media screen and (max-width:480px) {
    #cancha.form-343 .jugador.j00 {
        top: -2.000001%;
        left: 37.599999999%
    }
    #cancha.form-343 .jugador.j01 {
        top: 12.0000001%;
        left: 7.00001%
    }
    #cancha.form-343 .jugador.j02 {
        top: 12.0000001%;
        left: 37.599999999%
    }
    #cancha.form-343 .jugador.j03 {
        top: 12.0000001%;
        left: 68.00001%
    }
    #cancha.form-343 .jugador.j04 {
        top: 40.0000001%;
        left: 2.00001%
    }
    #cancha.form-343 .jugador.j05 {
        top: 34.0000001%;
        left: 22.00001%
    }
    #cancha.form-343 .jugador.j06 {
        top: 34.0000001%;
        left: 51.00001%
    }
    #cancha.form-343 .jugador.j07 {
        top: 40.0000001%;
        left: 72.00001%
    }
    #cancha.form-343 .jugador.j08 {
        top: 58.0000001%;
        left: 7.00001%
    }
    #cancha.form-343 .jugador.j09 {
        top: 58.0000001%;
        left: 37.599999999%
    }
    #cancha.form-343 .jugador.j10 {
        top: 58.0000001%;
        left: 68.00001%
    }
}