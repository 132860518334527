#cancha.form-4321 .jugador.j00 {
    top: 75%;
    left: 43.6%
}

#cancha.form-4321 .jugador.j01 {
    top: 62.5%;
    left: 20%
}

#cancha.form-4321 .jugador.j02 {
    top: 62.5%;
    left: 35%
}

#cancha.form-4321 .jugador.j03 {
    top: 62.5%;
    left: 51%
}

#cancha.form-4321 .jugador.j04 {
    top: 62.5%;
    left: 67%
}

#cancha.form-4321 .jugador.j05 {
    top: 50%;
    left: 26%
}

#cancha.form-4321 .jugador.j06 {
    top: 50%;
    left: 43.6%
}

#cancha.form-4321 .jugador.j07 {
    top: 50%;
    left: 61%
}

#cancha.form-4321 .jugador.j08 {
    top: 37.5%;
    left: 35%
}

#cancha.form-4321 .jugador.j09 {
    top: 37.5%;
    left: 51%
}

#cancha.form-4321 .jugador.j10 {
    top: 25%;
    left: 43.6%
}
@media screen and (max-width:992px) {
    #cancha.form-4321 .jugador.j00 {
        top: 73%;
        left: 41.6%
    }
    #cancha.form-4321 .jugador.j01 {
        top: 58%;
        left: 15%
    }
    #cancha.form-4321 .jugador.j02 {
        top: 58%;
        left: 33%
    }
    #cancha.form-4321 .jugador.j03 {
        top: 58%;
        left: 51%
    }
    #cancha.form-4321 .jugador.j04 {
        top: 58%;
        left: 69%
    }
    #cancha.form-4321 .jugador.j05 {
        top: 43%;
        left: 22%
    }
    #cancha.form-4321 .jugador.j06 {
        top: 43%;
        left: 41.6%
    }
    #cancha.form-4321 .jugador.j07 {
        top: 43%;
        left: 61%
    }
    #cancha.form-4321 .jugador.j08 {
        top: 28%;
        left: 33%
    }
    #cancha.form-4321 .jugador.j09 {
        top: 28%;
        left: 51%
    }
    #cancha.form-4321 .jugador.j10 {
        top: 13%;
        left: 41.6%
    }
}
@media screen and (max-width:767px) {
    #cancha.form-4321 .jugador.j00 {
        top: -2.000001%;
        left: 37.599999999%
    }
    #cancha.form-4321 .jugador.j01 {
        top: 19.0000001%;
        left: 2.00001%
    }
    #cancha.form-4321 .jugador.j02 {
        top: 12.0000001%;
        left: 22.00001%
    }
    #cancha.form-4321 .jugador.j03 {
        top: 12.0000001%;
        left: 51.00001%
    }
    #cancha.form-4321 .jugador.j04 {
        top: 19.0000001%;
        left: 72.00001%
    }
    #cancha.form-4321 .jugador.j05 {
        top: 34.0000001%;
        left: 7.00001%
    }
    #cancha.form-4321 .jugador.j06 {
        top: 34.0000001%;
        left: 37.599999999%
    }
    #cancha.form-4321 .jugador.j07 {
        top: 34.0000001%;
        left: 68.00001%
    }
    #cancha.form-4321 .jugador.j08 {
        top: 52.0000001%;
        left: 22.00001%
    }
    #cancha.form-4321 .jugador.j09 {
        top: 52.0000001%;
        left: 51.00001%
    }
    #cancha.form-4321 .jugador.j10 {
        top: 67.0000001%;
        left: 37.599999999%
    }
}
@media screen and (max-width:480px) {
    #cancha.form-4321 .jugador.j00 {
        top: -2.000001%;
        left: 37.599999999%
    }
    #cancha.form-4321 .jugador.j01 {
        top: 19.0000001%;
        left: 2.00001%
    }
    #cancha.form-4321 .jugador.j02 {
        top: 12.0000001%;
        left: 22.00001%
    }
    #cancha.form-4321 .jugador.j03 {
        top: 12.0000001%;
        left: 51.00001%
    }
    #cancha.form-4321 .jugador.j04 {
        top: 19.0000001%;
        left: 72.00001%
    }
    #cancha.form-4321 .jugador.j05 {
        top: 34.0000001%;
        left: 7.00001%
    }
    #cancha.form-4321 .jugador.j06 {
        top: 34.0000001%;
        left: 37.599999999%
    }
    #cancha.form-4321 .jugador.j07 {
        top: 34.0000001%;
        left: 68.00001%
    }
    #cancha.form-4321 .jugador.j08 {
        top: 52.0000001%;
        left: 22.00001%
    }
    #cancha.form-4321 .jugador.j09 {
        top: 52.0000001%;
        left: 51.00001%
    }
    #cancha.form-4321 .jugador.j10 {
        top: 67.0000001%;
        left: 37.599999999%
    }
}