#cancha.form-532 .jugador.j00 {
    top: 75%;
    left: 43.6%
}

#cancha.form-532 .jugador.j01 {
    top: 55%;
    left: 17%
}

#cancha.form-532 .jugador.j02 {
    top: 60%;
    left: 30%
}

#cancha.form-532 .jugador.j03 {
    top: 60%;
    left: 43.6%
}

#cancha.form-532 .jugador.j04 {
    top: 60%;
    left: 57%
}

#cancha.form-532 .jugador.j05 {
    top: 55%;
    left: 70%
}

#cancha.form-532 .jugador.j06 {
    top: 40%;
    left: 26%
}

#cancha.form-532 .jugador.j07 {
    top: 40%;
    left: 43.6%
}

#cancha.form-532 .jugador.j08 {
    top: 40%;
    left: 61%
}

#cancha.form-532 .jugador.j09 {
    top: 25%;
    left: 35%
}

#cancha.form-532 .jugador.j10 {
    top: 25%;
    left: 51%
}
@media screen and (max-width:992px) {
    #cancha.form-532 .jugador.j00 {
        top: 73%;
        left: 41.6%
    }
    #cancha.form-532 .jugador.j01 {
        top: 50%;
        left: 14%
    }
    #cancha.form-532 .jugador.j02 {
        top: 56.5%;
        left: 26%
    }
    #cancha.form-532 .jugador.j03 {
        top: 56.5%;
        left: 41.6%
    }
    #cancha.form-532 .jugador.j04 {
        top: 56.5%;
        left: 57%
    }
    #cancha.form-532 .jugador.j05 {
        top: 50%;
        left: 70%
    }
    #cancha.form-532 .jugador.j06 {
        top: 31%;
        left: 22%
    }
    #cancha.form-532 .jugador.j07 {
        top: 31%;
        left: 41.6%
    }
    #cancha.form-532 .jugador.j08 {
        top: 31%;
        left: 61%
    }
    #cancha.form-532 .jugador.j09 {
        top: 13%;
        left: 33%
    }
    #cancha.form-532 .jugador.j10 {
        top: 13%;
        left: 51%
    }
}
@media screen and (max-width:767px) {
    #cancha.form-532 .jugador.j00 {
        top: -2.000001%;
        left: 37.599999999%
    }
    #cancha.form-532 .jugador.j01 {
        top: 31.0000001%;
        left: 2.00001%
    }
    #cancha.form-532 .jugador.j02 {
        top: 18.0000001%;
        left: 14.00001%
    }
    #cancha.form-532 .jugador.j03 {
        top: 12.0000001%;
        left: 37.599999999%
    }
    #cancha.form-532 .jugador.j04 {
        top: 18.0000001%;
        left: 61.00001%
    }
    #cancha.form-532 .jugador.j05 {
        top: 31.0000001%;
        left: 72.00001%
    }
    #cancha.form-532 .jugador.j06 {
        top: 50.0000001%;
        left: 7.00001%
    }
    #cancha.form-532 .jugador.j07 {
        top: 50.0000001%;
        left: 37.599999999%
    }
    #cancha.form-532 .jugador.j08 {
        top: 50.0000001%;
        left: 68.00001%
    }
    #cancha.form-532 .jugador.j09 {
        top: 67.0000001%;
        left: 22.00001%
    }
    #cancha.form-532 .jugador.j10 {
        top: 67.0000001%;
        left: 51.00001%
    }
}
@media screen and (max-width:480px) {
    #cancha.form-532 .jugador.j00 {
        top: -2.000001%;
        left: 37.599999999%
    }
    #cancha.form-532 .jugador.j01 {
        top: 28.0000001%;
        left: 2.00001%
    }
    #cancha.form-532 .jugador.j02 {
        top: 18.0000001%;
        left: 17.00001%
    }
    #cancha.form-532 .jugador.j03 {
        top: 12.0000001%;
        left: 37.599999999%
    }
    #cancha.form-532 .jugador.j04 {
        top: 18.0000001%;
        left: 58.00001%
    }
    #cancha.form-532 .jugador.j05 {
        top: 28.0000001%;
        left: 72.00001%
    }
    #cancha.form-532 .jugador.j06 {
        top: 50.0000001%;
        left: 7.00001%
    }
    #cancha.form-532 .jugador.j07 {
        top: 50.0000001%;
        left: 37.599999999%
    }
    #cancha.form-532 .jugador.j08 {
        top: 50.0000001%;
        left: 68.00001%
    }
    #cancha.form-532 .jugador.j09 {
        top: 67.0000001%;
        left: 22.00001%
    }
    #cancha.form-532 .jugador.j10 {
        top: 67.0000001%;
        left: 51.00001%
    }
}